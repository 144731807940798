
import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';

interface ILoadingScreen {
  //What happens when loading starts
  displayLoadingUI: () => void;
  //What happens when loading stops
  hideLoadingUI: () => void;
  //default loader support. Optional!
  loadingUIBackgroundColor: string;
  loadingUIText: string;
}

export class CustomLoadingScreen implements ILoadingScreen {
    //optional, but needed due to interface definitions
    public loadingUIBackgroundColor: string;
    public loadingUIText: string;

    loadingScreenDiv : HTMLElement | null;

    constructor() {
        
    }

    public displayLoadingUI() {
      this.loadingScreenDiv = window.document.getElementById("babylonjsLoadingDiv");      
    }
  
    public hideLoadingUI() {
      if(this.loadingScreenDiv!=null){
        this.loadingScreenDiv.remove();
      }
    }
}