import * as BABYLON from 'babylonjs';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, PBRMaterial } from 'babylonjs';
import { PBRCustomMaterial } from 'babylonjs-materials';



export class VirusExhibit implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
    meshLoadTask : BABYLON.MeshAssetTask;

    textureLoadTask_hotspot_up : BABYLON.TextureAssetTask [] = [];
    textureLoadTask_hotspot_down : BABYLON.TextureAssetTask[] = [];

    textureHotSpotFiles : string[] = ['virion-art-standby.png', 'virion-art-01.png', 'virion-art-02.png', 'virion-art-03.png', 'virion-art-04.png'];
    textureLoadTask_hotspots : BABYLON.TextureAssetTask[] = [];
    
    anchor : BABYLON.Mesh;
    virus : BABYLON.Mesh;
    hotspots : BABYLON.Mesh[] = [];
    hotSpotIndex = 0;
    virusArtMaterial : BABYLON.PBRMaterial;

    dummyDissolveMesh : BABYLON.Mesh;
    virusMesh : BABYLON.AbstractMesh;
    virusDissolveMaterial : PBRCustomMaterial;
    virusNormalMaterial : BABYLON.Nullable<BABYLON.Material>;


    screenViewed : boolean[] = [false,false,false,false];

    inOutMusicTask : BABYLON.BinaryFileAssetTask;
    hoverMusicTask : BABYLON.BinaryFileAssetTask;

    inOutSound : BABYLON.Sound;
    hoverSound : BABYLON.Sound;

    pingSelectMusicTask : BABYLON.BinaryFileAssetTask;
    pingSelectSound : BABYLON.Sound;

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    }

    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
        this.meshLoadTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "VirionBake+Light.glb");

        for(let i=1; i<5;++i){
            this.textureLoadTask_hotspot_up.push(assetsManager.addTextureTask('textureLoadTask_hotspot' + i + '_up', './assets/textures/Sanofi/hotspot-0' + i + '-flublok-btn-up.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE));
            this.textureLoadTask_hotspot_down.push(assetsManager.addTextureTask('textureLoadTask_hotspot' + i + '_up', './assets/textures/Sanofi/hotspot-0' + i + '-fluzone-btn-up.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE));
        }

        for(let i = 0; i < this.textureHotSpotFiles.length; ++i){
            this.textureLoadTask_hotspots.push(assetsManager.addTextureTask("textureLoadTask_hotspot_" + i, './assets/textures/Sanofi/' + this.textureHotSpotFiles[i], false, false, BABYLON.Texture.TRILINEAR_SAMPLINGMODE));
        }

        this.inOutMusicTask = assetsManager.addBinaryFileTask("inOutMusicTask", "./assets/sounds/Arcade Game Negative Whoosh 1.mp3");
        this.hoverMusicTask = assetsManager.addBinaryFileTask("hoverMusicTask", "./assets/sounds/Vibrant Accept Button.mp3");
        this.pingSelectMusicTask = assetsManager.addBinaryFileTask("Pinger Sound task", "./assets/sounds/Chirp UI 1.mp3");
    }

    public OnAssetLoadComplete () {

        //Sounds
        this.inOutSound = new BABYLON.Sound("inOutSound", this.inOutMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });
        this.hoverSound = new BABYLON.Sound("virusHoverSound", this.hoverMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });
        this.pingSelectSound = new BABYLON.Sound("triviaCorrectSound", this.pingSelectMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });

        
        //Load the Virus model
        let meshes = this.meshLoadTask.loadedMeshes;
        if(meshes != null){
            
            //Collider so we don't walk into the virus
            var sphere = BABYLON.Mesh.CreateSphere("Virus_Root", 5.0, 3.0, this.scene);
            this.anchor = sphere;
            sphere.checkCollisions = true;
            sphere.isVisible = false;                    
            sphere.position = new BABYLON.Vector3(0,0,-21);
            sphere.isPickable = true;

            for(var i=0;i<meshes.length;i++) {
                
                meshes[i].checkCollisions = false;
                meshes[i].isPickable = false;

                if(meshes[i].name == "__root__"){
                    meshes[i].parent = sphere;
                    this.virus = meshes[i] as BABYLON.Mesh; 
                    this.virus.rotationQuaternion = null;      
                }

                if(meshes[i].name == "Virion"){                    

                    this.virusMesh = meshes[i];
                    this.virusNormalMaterial = this.virusMesh.material;   
                    this.virusDissolveMaterial = this.customMaterial(); 
                    //this.virusMesh.material = this.virusDissolveMaterial;
                }

                    
                   
            }        

            //Add hotspots
            for(let i=0; i < 4; ++i){

                //Create mesh
                let hotspotMesh : BABYLON.Mesh = BABYLON.Mesh.CreatePlane("virusHotspot_" + i, 0.75, this.scene);
                hotspotMesh.parent = sphere;
                hotspotMesh.rotationQuaternion = null;
                hotspotMesh.rotation.y = Math.PI;
                //hotspotMesh.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
                
                //hotspotMesh.rotate(BABYLON.Axis.Y, Math.PI, BABYLON.Space.WORLD);
                hotspotMesh.isPickable = true;
                this.hotspots.push(hotspotMesh);
                
                //Create material
                let hotspotMaterial : StandardMaterial = new BABYLON.StandardMaterial("hotspotMaterial_" + i, this.scene);    
                hotspotMaterial.diffuseTexture = this.textureLoadTask_hotspot_up[i].texture;
                hotspotMaterial.diffuseTexture.hasAlpha = true;
                hotspotMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
                hotspotMesh.material = hotspotMaterial;

                //Add hotspot listeners
                hotspotMesh.actionManager = new BABYLON.ActionManager(this.scene);
                hotspotMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{    
                    this.pingSelectSound.play();  
                    let hotspotNumber = i + 1;
                    this.onHotSpotClick(hotspotNumber);  
                }));

                //Make buttons larger when hovered
                hotspotMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, ()=>{
                    if(this.blockHotspots) return;
                    hotspotMesh.scaling =  new BABYLON.Vector3(1.1, 1.1, 1.1);
                    this.hoverSound.play();
                }));

                hotspotMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, ()=>{
                    if(this.blockHotspots) return;
                    hotspotMesh.scaling =  BABYLON.Vector3.One();
                }));
                 
            }

            this.hotspots[0].position = new BABYLON.Vector3(-3.8, 4.1, -1.9);
            this.hotspots[1].position = new BABYLON.Vector3(-3.8, 3.1, -1.9);
            this.hotspots[2].position = new BABYLON.Vector3(-3.8, 2.1, -1.9);
            this.hotspots[3].position = new BABYLON.Vector3(-3.8, 1.1, -1.9);
            

            //Find the background
            let virusArt = this.scene.getMeshByName('Art03') as BABYLON.Mesh;
            this.virusArtMaterial = virusArt.material as BABYLON.PBRMaterial;
            this.virusArtMaterial.emissiveTexture = this.textureLoadTask_hotspots[0].texture;
            this.virusArtMaterial.albedoTexture = this.textureLoadTask_hotspots[0].texture;            
            virusArt.material = this.virusArtMaterial;           
            

            //Dummy for dissolve
            this.dummyDissolveMesh = BABYLON.Mesh.CreatePlane("dummyDissolveMesh", 1, this.scene);
            this.dummyDissolveMesh.isPickable = false;
            this.dummyDissolveMesh.isVisible = false;
            this.dummyDissolveMesh.checkCollisions = false;
            this.dummyDissolveMesh.scaling = BABYLON.Vector3.Zero();


            // Create a particle system
            var particleSystem = new BABYLON.ParticleSystem("particles", 100, this.scene);
            
            
            //Texture of each particle
            particleSystem.particleTexture = new BABYLON.Texture("./assets/textures/particles/flare.png", this.scene);
        
            // Where the particles come from
            //let particleEmitterPosition = this.anchor.position.add(new BABYLON.Vector3(0,-1,0 )); 
            particleSystem.emitter = this.anchor;//particleEmitterPosition; // the starting object, the emitter
        
            // Colors of all particles
            particleSystem.color1 = new BABYLON.Color4(1, 1,1, 1.0);
            particleSystem.color2 = new BABYLON.Color4(0, 1, 0, 1.0);
            particleSystem.colorDead = new BABYLON.Color4(1, 1, 1, 1);
        
            // Size of each particle (random between...
            particleSystem.minSize = 0.05;
            particleSystem.maxSize = 0.15;
        
            // Life time of each particle (random between...
            particleSystem.minLifeTime = 0.1;
            particleSystem.maxLifeTime = 1;
        
            // Emission rate
            particleSystem.emitRate = 50;
        
            // Blend mode : BLENDMODE_ONEONE, or BLENDMODE_STANDARD
            particleSystem.blendMode = BABYLON.ParticleSystem.BLENDMODE_ONEONE;
        
            // Set the gravity of all particles
            particleSystem.gravity = new BABYLON.Vector3(0, 10, 0);
        
        
            // Speed
            particleSystem.minEmitPower = 0;
            particleSystem.maxEmitPower = 0;
            particleSystem.updateSpeed = 0.005;
        
            // Start the particle system
            particleSystem.start();

        }
        

        

    }
    
    public Update () {
        /*
        for(let i=0; i < this.hotspots.length; ++i){
            //console.log(i);
            //this.hotspots[i].lookAt(this.camera.position);
            //this.hotspots[i].rotation.y += 0.1;

            var delta = this.hotspots[i].position.subtract(this.camera.position);
            var yaw = -Math.atan2(delta.z, delta.x) - Math.PI / 2;

            console.log(yaw);

            this.hotspots[i].rotation.y = yaw;
            this.hotspots[i].rota rotate(BABYLON.Axis.Y, yaw, BABYLON.Space.WORLD)
           
        }
        */
    }

   
    blockHotspots = false;
    onHotSpotClick(i : number){

        if(this.blockHotspots) return;

        let hotspotMaterial : StandardMaterial;

        this.screenViewed[i-1] = true;

        let haveAll = true;
        for(let i=0; i<this.screenViewed.length;++i){
            if(!this.screenViewed[i]){
                haveAll = false;
                break;
            }
        }

        if(haveAll){
            this.blockHotspots = true;

            
            let easingFunction = new BABYLON.QuadraticEase();
            easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEINOUT);

            this.virusMesh.material = this.virusDissolveMaterial;
            this.inOutSound.play();
            BABYLON.Animation.CreateAndStartAnimation('dummyDissolveMeshAnimation2', this.dummyDissolveMesh, 'scaling.x', 60, 60, 0, 1, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, ()=>{
                setTimeout(()=>{

                    hotspotMaterial = this.hotspots[this.hotSpotIndex - 1].material as StandardMaterial;
                    hotspotMaterial.diffuseTexture = this.textureLoadTask_hotspot_up[this.hotSpotIndex-1].texture;
                    hotspotMaterial.diffuseTexture.hasAlpha = true;
                    hotspotMaterial.emissiveColor = new BABYLON.Color3(1,1,1);

                    this.hotSpotIndex = 0;  
                    this.virusArtMaterial.emissiveTexture = this.textureLoadTask_hotspots[this.hotSpotIndex].texture;
                    this.virusArtMaterial.albedoTexture = this.textureLoadTask_hotspots[this.hotSpotIndex].texture; 

                    this.inOutSound.play();
                    BABYLON.Animation.CreateAndStartAnimation('dummyDissolveMeshAnimation2', this.dummyDissolveMesh, 'scaling.x', 60, 60, 1, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, ()=>{
                        this.virusMesh.material = this.virusNormalMaterial;

                        for(let i=0; i<this.screenViewed.length;++i){
                            this.screenViewed[i] = false;
                        }

                        this.blockHotspots = false;

                           
                    }) ;
                
                } ,3000);

            }) ;
           
            

        }

        

        //Turn off any active hotspot
        if(this.hotSpotIndex > 0){
            hotspotMaterial = this.hotspots[this.hotSpotIndex - 1].material as StandardMaterial;
            hotspotMaterial.diffuseTexture = this.textureLoadTask_hotspot_up[this.hotSpotIndex-1].texture;
            hotspotMaterial.diffuseTexture.hasAlpha = true;
            hotspotMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
        }

        //Turn on hotspot if applicable
        hotspotMaterial  = this.hotspots[i-1].material as StandardMaterial;

       

        if(i != this.hotSpotIndex){
            this.hotSpotIndex = i;
            hotspotMaterial.diffuseTexture = this.textureLoadTask_hotspot_down[i-1].texture;
            hotspotMaterial.diffuseTexture.hasAlpha = true;
            hotspotMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
                     

        } else {
            this.hotSpotIndex = 0;   
        }

        
        this.virusArtMaterial.emissiveTexture = this.textureLoadTask_hotspots[this.hotSpotIndex].texture;
        this.virusArtMaterial.albedoTexture = this.textureLoadTask_hotspots[this.hotSpotIndex].texture;     




        
                    
       


    }

    haveAddedDissolveMat = false;

    customMaterial = () => {

        let mat = new PBRCustomMaterial("mat", this.scene);
        let pbr : PBRMaterial = this.virusNormalMaterial as PBRMaterial;
        
        mat.AddUniform('time','float',{});
        mat.AddUniform('noise','sampler2D',{});
        mat.AddUniform('dissolve','float',{});
        mat.albedoTexture = pbr.albedoTexture;//new BABYLON.Texture("./asset/textures/sand.jpg", this.scene);
        mat.backFaceCulling = false;
        mat.metallic = 1.0;
        mat.roughness = 1.0;
        mat.enableSpecularAntiAliasing = true;
        mat.unlit = true;
    
        mat.Vertex_Definitions(
            `varying vec3 vPosition;
            varying vec2 vUv;
            varying vec3 vNoise;`
        );
    
        mat.Fragment_Definitions(
            `varying vec2 vUv;
            varying vec3 vNoise;`
        );
    
    
        mat.Vertex_MainEnd(
            `vPosition = worldPos.xyz;
            vUv = uv;`
        );
    
    
        mat.Fragment_Before_FragColor(
            `
            float n = texture2D( noise, vUv ).x - dissolve;
            vec4 albedo = texture2D( albedoSampler, vUv );
            vec3 albedoColor = albedoTexture.rgb;
            if (n < 0.0) { discard; }
            if (n < 0.10) { finalColor.rgb = vec3(0.0,1.0,0.0); }
            if (n < 0.07) { finalColor.rgb = vec3(0.0,0.8,0.0); }
            if (n < 0.05) { finalColor.rgb = vec3(0.0,0.6,0.0); }
            `
        );
        
        //var duration = 3;
        
        var amount = -0.008;
        var tex = new BABYLON.NoiseProceduralTexture("perlin", 1024, this.scene);
        tex.animationSpeedFactor = 1;
        tex.persistence = .7;
        tex.brightness = .5;
        tex.octaves = 14;

        
    
        
        mat.onBindObservable.add(  (t) => { 
            mat.getEffect().setFloat('dissolve', this.dummyDissolveMesh.scaling.x);
            if(!this.haveAddedDissolveMat){
                this.haveAddedDissolveMat = true;
                mat.getEffect().setTexture('noise',tex);
            }

            
            
        });
        

        

        return mat;
    
    }
   


}

