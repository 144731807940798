import * as React from 'react';
import BabylonScene from './Scene'; 
import {SceneEventArgs} from './Scene';


import * as BABYLON from 'babylonjs';
import { AssetsManager, CubeTexture, DefaultLoadingScreen, Vector3, Mesh, BinaryFileAssetTask, Observable } from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import 'babylonjs-loaders'; 


import NSMSceneBehavior from './NSMSceneBehavior';
import {MainGallery} from './Components/MainGallery';
import {VirusExhibit} from './Components/VirusExhibit';
import {ModalCallbacks, ImageCarouselAsset} from './PageWithScene';
import {HTMLArt, HTMLCarouselArt, HTMLVideoArt} from './GalleryInteractives'
import {CustomLoadingScreen} from './Components/LoadingScreen';
import {TriviaExhibit} from './Components/TriviaExhibit';
import { refractionPixelShader } from 'babylonjs/Shaders/refraction.fragment';
import {ScrollingTextureExhibit} from './Components/ScrollingTextureExhibit';
import {Pinger} from './Components/Pinger';
import { TotumExhibit } from './Components/TotumExhibit';
import {WelcomeVideoExhibit} from './Components/WelcomeVideoExhibit';
import {ScreenShareDisplay} from './Components/ScreenShareDisplay';

import {WebSocket} from './Components/WebSocket';
import {Agora} from './Components/Agora';

import { Vector2WithInfo } from 'babylonjs-gui';
 
let nsmSceneBehaviors : NSMSceneBehavior[] = []; 

let ambientMusicTask : BinaryFileAssetTask;
let ambientMusic : BABYLON.Sound;
let ambientIsPlaying = false;

const NSMGalleryScene = (e : SceneEventArgs, modalCallbacks : ModalCallbacks) => {
    const { canvas, scene, engine } = e;

    //BABYLON.Engine.audioEngine.useCustomUnlockedButton = false;

    //scene.debugLayer.show();

    
    
    let loadingScreen = new CustomLoadingScreen();
    engine.loadingScreen = loadingScreen;
    engine.displayLoadingUI();
    
   
    // This creates and positions a free camera (non-mesh)
    var camera = new BABYLON.FreeCamera("MainCamera", new BABYLON.Vector3(0, 2, 3.5), scene);// new BABYLON.FreeCamera("MainCamera", new BABYLON.Vector3(0, 2, 9), scene);   
    camera.minZ = 0.1; 
    camera.fov = 65 * Math.PI / 180;    
    camera.setTarget(BABYLON.Vector3.Zero()); // This targets the camera to scene origin        
    //camera.attachControl(canvas, true);// This attaches the camera to the canvas
    camera.speed = 0.25;
    camera.rotation.x = -2 * Math.PI / 180;

    camera.angularSensibility *= -1;
    //camera.inputs.removeByType("FreeCameraTouchInput");
    //camera.inputs.removeByType("FreeCameraKeyboardMoveInput");
    //camera.inputs.add ("FreeCameraKeyboardMoveInput");

    //Scene HDR    
    /*
    var hdrTexture = new BABYLON.HDRCubeTexture("./assets/textures/HDRI/white_cliff_top_2k.hdr", scene, 512, false, true, false);     
    
    var hdrRotation = 79; // in degrees
    hdrTexture.setReflectionTextureMatrix(
        BABYLON.Matrix.RotationY(
            BABYLON.Tools.ToRadians(hdrRotation)
        )
    );

    scene.createDefaultSkybox(hdrTexture, true, 2000);    
    scene.createDefaultEnvironment({
        environmentTexture: hdrTexture
    })
    */
   
    //Render pipeline 
    
    var pipeline = new BABYLON.DefaultRenderingPipeline(
        "defaultPipeline", // The name of the pipeline
        true, // Do you want the pipeline to use HDR texture?
        scene, // The scene instance
        [camera] // The list of cameras to be attached to
    );
    //pipeline.samples = 4;
    pipeline.fxaaEnabled = true;    
    

    //Setup asset manager
    let assetsManager : BABYLON.AssetsManager = new BABYLON.AssetsManager(scene);
    //assetsManager.useDefaultLoadingScreen = false;

    //Setup action manager
    scene.actionManager = new BABYLON.ActionManager(scene);

    /********************************************************
    * 
    * SCENE SETUP
    * 
    *******************************************************/

  

    



    //Main gallery and walk mechanics
    let mainGallery : MainGallery = new MainGallery();
    nsmSceneBehaviors.push(mainGallery);


    //Stop 1 - Welcome
    let artStop1 : WelcomeVideoExhibit = new WelcomeVideoExhibit("Art01", "./assets/videos/LOGO_BUILD_01_043020_rev01-1280.mp4", "./assets/textures/LOGO-BUILD_01_043020-1280.png", ()=>{
        console.log("Video complete");
        //playAmbient();
    });
    nsmSceneBehaviors.push(artStop1);  


    //Stop 2 - Art
    let artStop2Data: ImageCarouselAsset[] = [
        {title: "Title 1", text: "Text 1", imagePath: "./assets/textures/Art-02-Interactive/Aramco-01.png"},
        {title: "Title 2", text: "Text 2", imagePath: "./assets/textures/Art-02-Interactive/MHS-Global.png"},
        {title: "Title 3", text: "Text 3", imagePath: "./assets/textures/Art-02-Interactive/Rigel-RFID.png"}
    ];
    let artStop2: HTMLCarouselArt = new HTMLCarouselArt("Art02", "", "", "", modalCallbacks.titleTextAndImageCarouselCallback, artStop2Data);
    artStop2.SetDisplayImage('./assets/textures/Art-02-Interactive/ArtStop-02-interactive.png');
    nsmSceneBehaviors.push(artStop2);   



    //Stop 3 - Virus model 3d exhibit
    let virusExhibit : VirusExhibit = new VirusExhibit();
    nsmSceneBehaviors.push(virusExhibit);


    //Stop 4 
    //video
    let videoModal: HTMLVideoArt = new HTMLVideoArt("Art04", modalCallbacks.titleTextAndVideoCallback, "./assets/textures/Art-04-Mosaic/GE-Mosaic.mp4", "Art 4 Title", "Art 4 Subtitle", "Art4 Text");
    videoModal.SetDisplayImage("./assets/textures/Art-04-Mosaic/ArtStop-04-mosaic.png");
    nsmSceneBehaviors.push(videoModal); 

    //Stop 5
    let artStop5Data: ImageCarouselAsset[] = [
        {title: "Title 1", text: "Text 1", imagePath: "./assets/textures/Art-05-AV/SMT-2020.022.png"},
        {title: "Title 2", text: "Text 2", imagePath: "./assets/textures/Art-05-AV/SMT-2020.025.png"},
        {title: "Title 3", text: "Text 3", imagePath: "./assets/textures/Art-05-AV/SMT-2020.027.png"},
        {title: "Title 4", text: "Text 4", imagePath: "./assets/textures/Art-05-AV/SMT-2020.054.png"}
    ];
    let artStop5: HTMLCarouselArt = new HTMLCarouselArt("Art05", "", "", "", modalCallbacks.titleTextAndImageCarouselCallback, artStop5Data);
    artStop5.SetDisplayImage('./assets/textures/Art-05-AV/ArtStop-05-av.png');
    nsmSceneBehaviors.push(artStop5);

    //Stop 6
    let artStop6Data: ImageCarouselAsset[] = [
        {title: "Title 1", text: "Text 1", imagePath: "./assets/textures/Art-06-IT/computer-rental.png"},
        {title: "Title 2", text: "Text 2", imagePath: "./assets/textures/Art-06-IT/wifi.png"}
    ];
    let artStop6: HTMLCarouselArt = new HTMLCarouselArt("Art06", "", "", "", modalCallbacks.titleTextAndImageCarouselCallback, artStop6Data);
    artStop6.SetDisplayImage('./assets/textures/Art-06-IT/ArtStop-06-it.png');
    nsmSceneBehaviors.push(artStop6);

    //Stop 7 - Trivia
    let triviaExhibit = new TriviaExhibit();
    nsmSceneBehaviors.push(triviaExhibit);

    //Scrolling Leaderboard
    let scrollingTextureExhibit : ScrollingTextureExhibit = new ScrollingTextureExhibit();
    nsmSceneBehaviors.push(scrollingTextureExhibit);


    //Pingers
    //let pinger1 = new Pinger("Art01_Pinger", new Vector3(0, 0.05, -3), new Vector3( Math.PI / 2, 0, 0), 2, true, ()=>{artStop1.playVideo()});    
    //nsmSceneBehaviors.push(pinger1);

    let pinger2 = new Pinger("Art02_Pinger", new Vector3(-3.4, 2, -6.6), new Vector3(0, 0, 0), 0.5, false, ()=>{});    
    nsmSceneBehaviors.push(pinger2);

    let pinger4 = new Pinger("Art04_Pinger", new Vector3(3, 2, -23.7), new Vector3(0, 0, 0), 0.5, false, ()=>{});    
    nsmSceneBehaviors.push(pinger4);

    let pinger5 = new Pinger("Art05_Pinger", new Vector3(14.7, 2.5, -12.3), new Vector3(0, Math.PI / 2, 0), 0.5, false, ()=>{});    
    nsmSceneBehaviors.push(pinger5);

    let pinger6 = new Pinger("Art06_Pinger", new Vector3(-14.7, 2.5, -17.3), new Vector3(0, -Math.PI / 2, 0), 0.5, false, ()=>{});    
    nsmSceneBehaviors.push(pinger6);

    


    //Totum Video
    //Fake an in-model mesh
    let totumMesh: BABYLON.Mesh = BABYLON.MeshBuilder.CreatePlane("totumMesh", {width: 2.5, height: 5}, scene);
    totumMesh.position = new BABYLON.Vector3(-15, 3.1, 2.4);
    let totumRotAxis: BABYLON.Vector3 = new Vector3(0, 1, 0);
    let totAng: number = -Math.PI / 2;
    let totQuat: BABYLON.Quaternion = BABYLON.Quaternion.RotationAxis(totumRotAxis, totAng);
    totumMesh.rotationQuaternion = totQuat;
    let totumExhibit: TotumExhibit = new TotumExhibit("totumMesh", "./assets/videos/totum_1.mp4", "./assets/textures/frame1.png");
    nsmSceneBehaviors.push(totumExhibit);


    



    //Sound
    ambientMusicTask = assetsManager.addBinaryFileTask("Ambient Music task", "./assets/sounds/lyell_by_kevin-graham_Artlist.mp3");
    ambientMusicTask.onSuccess = function (task) {
       ambientMusic = new BABYLON.Sound("ambientMusic", ambientMusicTask.data, scene, undefined, { volume: 1.0, autoplay: false, loop: true });
    }
    
    



    //let webSocket = new WebSocket();
    //nsmSceneBehaviors.push(webSocket);
    

    let uidCallback = (uid:string|number) => {
        screenShareDisplay.showScreenShare(uid);
    }

    let agora = new Agora(uidCallback);
    nsmSceneBehaviors.push(agora);

    let screenSelectCallback = () => {
        agora.shareScreen();
    }

    let screenShareDisplay : ScreenShareDisplay = new ScreenShareDisplay(screenSelectCallback);
    nsmSceneBehaviors.push(screenShareDisplay);





    //Start GUI
    let advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI");
    //advancedTexture.rootContainer.adaptWidthToChildren = true;
    //advancedTexture.rootContainer.adaptHeightToChildren = true;
    
     let startButton = GUI.Button.CreateImageOnlyButton(
         "startButton",
         "./assets/textures/start-btn.png"
     );
     startButton.width = "402px";
     startButton.height = "122px";
     startButton.thickness = 0;
     startButton.top = "9%";//90;
 
     var rect1 = new GUI.Rectangle();
     rect1.width = "100%";
     rect1.height = "100%";
     rect1.background = "#00000000";
     rect1.thickness = 0;
     advancedTexture.addControl(rect1); 
     
     //rect1.horizontalAlignment =  GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
 
     startButton.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
     startButton.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER;
 
     advancedTexture.addControl(startButton);    
 
     let startExperience = () => {
         advancedTexture.removeControl(startButton);
         advancedTexture.removeControl(rect1);
         
         mainGallery.EnableWalkAround();
         //artStop1.playVideo();
         camera.attachControl(canvas, true);

         //var person = prompt("Please enter your name:", "");
         //if (person == null || person == "") {
           //person = "Unknown"
         //}


         //webSocket.connectSocket(person);
         agora.connect();

         
     }
 
     startButton.onPointerClickObservable.add( startExperience);
 
     rect1.isPointerBlocker = true;
     rect1.onPointerClickObservable.add(startExperience);


   







    
    


    /********************************************************
    * 
    * LifeCycle Startup
    * 
    *******************************************************/   

    nsmSceneBehaviors.forEach(function (sceneBehavior) {
        sceneBehavior.Start(e, camera);
        sceneBehavior.LoadAssets(assetsManager);
    }); 
   


    /********************************************************
    * 
    * Asset Loading and Run Loop
    * 
    *******************************************************/      
        
    assetsManager.onFinish = function(tasks) {

        nsmSceneBehaviors.forEach(function (sceneBehavior) {
            sceneBehavior.OnAssetLoadComplete();
        }); 

        engine.runRenderLoop(() => {
            if (scene) {
                nsmSceneBehaviors.forEach(function (sceneBehavior) {
                    sceneBehavior.Update();
                    update();
                }); 

                
                scene.render();
            }
        });
    };
    assetsManager.load();
//

    let update = () => {
        checkAmbientMusic();
    }


    let checkAmbientMusic = () => {
        if(camera.position.z < -6){
            //playAmbient();
        }

    }

    let playAmbient = () => {       
       return;
       /*
        if(ambientIsPlaying || ambientMusic.isPlaying) return;
        console.log("Play Ambient B");
        ambientIsPlaying = true;
        ambientMusic.play();
        */
        //
    }



    let audioEngineUnlocked = false;
    let onPointerObservable = (pointerInfo : BABYLON.PointerInfo) =>{
        
        switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN:
                //console.log("POINTER DOWN");
                unlockAudio();
                break;
            case BABYLON.PointerEventTypes.POINTERUP:
                //console.log("POINTER UP");
                break;
            case BABYLON.PointerEventTypes.POINTERMOVE:
                //console.log("POINTER MOVE");
              
                //unlockAudio();
               
                

                break;
            case BABYLON.PointerEventTypes.POINTERWHEEL:
                //console.log("POINTER WHEEL");
                break;
            case BABYLON.PointerEventTypes.POINTERPICK:
                //console.log("POINTER PICK");
                //unlockAudio();
                break;
            case BABYLON.PointerEventTypes.POINTERTAP:
                //console.log("POINTER TAP");
                //unlockAudio();
                

                break;
            case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
                //console.log("POINTER DOUBLE-TAP");

                
                break;
        }
    }
    scene.onPointerObservable.add(onPointerObservable);

    let unlockAudio = () => {
        //if(audioEngineUnlocked) return;
        //audioEngineUnlocked = true;
        //BABYLON.Engine.audioEngine.unlock();
        //console.log("UNLOCK");
    }

           
}




export default NSMGalleryScene;