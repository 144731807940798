import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, Vector3, Mesh, Material } from 'babylonjs';
import { throws } from 'assert';
import { timingSafeEqual } from 'crypto';



export class ScreenShareDisplay implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
    screenDisplayMesh : BABYLON.Mesh;

    videoTexture: BABYLON.VideoTexture;
    videoMaterial: BABYLON.StandardMaterial;


    constructor( public onSelectCallback : () => void){
       
    }
   
    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    } 

    public LoadAssets (assetsManager : BABYLON.AssetsManager)  { 
        
      
        
    } 
           
           
    public OnAssetLoadComplete () { 

        this.screenDisplayMesh = BABYLON.MeshBuilder.CreatePlane("screenShareArt", {width: 4, height : 2.25}, this.scene);

        this.screenDisplayMesh.position = new BABYLON.Vector3(14,2,2);
        this.screenDisplayMesh.rotation.y = Math.PI / 2;
        
        this.screenDisplayMesh.actionManager = new BABYLON.ActionManager(this.scene);
        this.screenDisplayMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{        
            
            this.onSelectCallback();
            
        }));
      

    }

    public getScreenMesh  = () : BABYLON.Mesh  =>  {
        return this.screenDisplayMesh;
    }
    
    public Update () {
      
    }

    public showScreenShare = (uid : string | number) => {
        let videoID = 'video' + uid;
        let videoNode = document.getElementById(videoID) as HTMLVideoElement;

        this.videoTexture = new BABYLON.VideoTexture("ScreenShareTexture", videoNode, this.scene);
        this.videoMaterial = new StandardMaterial("totumVideoMat", this.scene);
        this.videoMaterial.diffuseTexture = this.videoTexture;
        this.videoMaterial.emissiveColor = new BABYLON.Color3(1,1,1);

        this.screenDisplayMesh.material = this.videoMaterial;

    }

}

