import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';
import { Vector3 } from 'babylonjs';

import AgoraRTC, { StreamSpec } from 'agora-rtc-sdk';
import { timingSafeEqual } from 'crypto';


import UAParser from 'ua-parser-js'

interface RTCInterface {
    client: AgoraRTC.Client | null,
    joined: boolean,
    published: boolean,
    localStream: AgoraRTC.Stream | null,
    remoteStreams: [],
    params: RTCParams
}

interface RTCParams{
    uid : string | number
}

const parser = new UAParser.UAParser()

const userAgentInfo = parser.getResult();

const isSafari = () => {
    return (
      userAgentInfo.browser.name === 'Safari' ||
      userAgentInfo.browser.name === 'Mobile Safari'
    )
  }
  
  const isCompatibleChrome = () => {
    if (userAgentInfo.browser.name === 'Chrome') {
      if(userAgentInfo.browser.major != undefined){
        const major = +userAgentInfo.browser.major
        if (major >= 72) return true
      }
    }
    return false
  }
  
  const isFirefox = () => {
    return userAgentInfo.browser.name === 'Firefox'
  }

export class Agora implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
   
    
    rtc : RTCInterface = {
        client: null,
        joined: false,
        published: false,
        localStream: null,
        remoteStreams: [],
        params: {uid : ""}
    };
    
    // Options for joining a channel
    option = {
        appID: "3c01a58fbef4440989cdd959c4308b49",
        channel: "NSM",
        screenChannel : "NSMScreenChannel",
        uid: null,
        token: null
    };


    screenClient = AgoraRTC.createClient({
        mode: 'rtc',
        codec: 'vp8'
    });

    localStreams : (string|number)[] = [];
    uid : string | number;
    
    
    constructor(public uidCallback : (_uid:string|number) => void){

    }

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    }


    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
      

    }

    
    public OnAssetLoadComplete () {        

        this.screenClient.init(this.option.appID, () => {                      

            this.screenClient.join(null, this.option.screenChannel, null, (_uid) => {     

                // Save the uid of the local stream.
                 this.localStreams.push(_uid);
                
                this.uid = _uid;
                
                // Listen to the 'stream-added' event.
                this.screenClient.on('stream-added', (evt) => {

                    console.log("STREAM ADDED");

                    var stream = evt.stream;
                    var uid = stream.getId()
    
                    // Check if the stream is a local uid.
                    if (!this.localStreams.includes(uid)) {

                        console.log('subscribe stream:' + uid);
                        // Subscribe to the stream.
                        this.screenClient.subscribe(stream);

                    } else {
                        console.log("IGNORING STREAM ADDED");
                    }
                });

                this.screenClient.on("stream-subscribed", (evt) => {
                    var remoteStream = evt.stream;
                    var id = remoteStream.getId();
                    // Add a view for the remote stream.
                    //addView(id);
        
                    let remoteStreamNode = document.getElementById("remoteStreams");
                    if(remoteStreamNode != null){
                        var node = document.createElement("div");
                        node.id = "remote_stream_" + id;
                        remoteStreamNode.appendChild(node);
                    }
        
        
                    // Play the remote stream.
                    remoteStream.play("remote_stream_" + id);
                    console.log("stream-subscribed remote-uid: ", id);

                    this.uidCallback(id);

                });
               
        
            }, function(err) {
                console.log(err);
            })
            
        });
        

    }

    public connect = () => {
         




         /*
        AgoraRTC.Logger.setLogLevel(3);

        this.rtc.client = AgoraRTC.createClient({mode: "rtc", codec: "h264"});

        // Initialize the client
        this.rtc.client.init(this.option.appID, () => {

            console.log("init success");

            if(this.rtc.client != null){
                this.rtc.client.join(this.option.token, this.option.channel, this.option.uid, (uid) => {

                    console.log("join channel: " + this.option.channel + " success, uid: " + uid);
                    this.rtc.params.uid = uid;


                    this.rtc.localStream = AgoraRTC.createStream({
                        streamID: this.rtc.params.uid,
                        audio: true,
                        video: false,
                        screen: false,
                    });

                    this.rtc.localStream.init(() => {
                        console.log("init local stream success");
                        // play stream with html element id "local_stream"
                        if(this.rtc.localStream != null){
                            this.rtc.localStream.play("local_stream");
                        }

                        if(this.rtc.client != null && this.rtc.localStream != null){
                                this.rtc.client.publish(this.rtc.localStream, function (err) {
                                    console.log("publish failed");
                                    console.error(err);
                                });
                            
                        }

                    }, function (err) {
                    console.error("init local stream failed ", err);
                    });


                }, function(err) {


                    console.error("client join failed", err);


                });
            }

            


        }, (err) => {




          console.error(err);
        });



        this.rtc.client.on("stream-added", (evt) => {  
            var remoteStream = evt.stream;
            var id = remoteStream.getId();
            if (id !== this.rtc.params.uid) {
                if(this.rtc.client != null){
                    this.rtc.client.subscribe(remoteStream, {video:false, audio:true}, (err) => {
                        console.log("stream subscribe failed", err);
                    });
                }
            }
            console.log("stream-added remote-uid: ", id);
        });

        this.rtc.client.on("stream-subscribed", (evt) => {
            var remoteStream = evt.stream;
            var id = remoteStream.getId();
            // Add a view for the remote stream.
            //addView(id);

            let remoteStreamNode = document.getElementById("remoteStreams");
            if(remoteStreamNode!= null){
                var node = document.createElement("div");
                node.id = "remote_stream_" + id;
                remoteStreamNode.appendChild(node);
            }


            // Play the remote stream.
            remoteStream.play("remote_stream_" + id);
            console.log("stream-subscribed remote-uid: ", id);
        });



        */

         
    }
    
    public Update () {

     
    }


    public shareScreen = () => {
        
        
                
            

            // Create the stream for screen sharing.
            const streamSpec : AgoraRTC.StreamSpec = {
                streamID: this.uid,
                audio: false,
                video: false,
                screen: true,
                screenAudio: true,
                mediaSource : undefined,
                extensionId : undefined
            }

            // Set relevant attributes according to the browser.
            // Note that you need to implement isFirefox and isCompatibleChrome.

            //
            if (isFirefox()) {
                streamSpec.mediaSource = 'window';
            } else if (!isCompatibleChrome()) {
                streamSpec.extensionId = 'minllpmhdgpndnkomcoccfekfegnlikg';
            }

            let screenStream = AgoraRTC.createStream(streamSpec);

            
            // Initialize the stream.
            screenStream.init(() => {
                

                // Play the stream.
                screenStream.play('ScreenShare');
                // Publish the stream.
                this.screenClient.publish(screenStream);

                this.uidCallback(this.uid);

                screenStream.on("stopScreenSharing",()=>{

                    console.log("WE ARE STOPPED");

                });
                
                
    
                // Listen to the 'stream-added' event.
               
    
            }, function(err) {
                console.log(err);
            });
        
    
    }


}
  
    