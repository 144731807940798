import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, Vector3, Color3, Engine, Mesh, VideoTextureSettings} from 'babylonjs';
import { throws } from 'assert';



export class WelcomeVideoExhibit implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
    imageTextureTask : BABYLON.TextureAssetTask;

    
    videoTexture: BABYLON.VideoTexture;
    videoMaterial: BABYLON.StandardMaterial;
    displayMesh: BABYLON.Mesh;
    imageMaterial: BABYLON.StandardMaterial;

    isPlaying = false;

    playedOnce = false;

    constructor(public meshName: string, public videoPath: string, public imagePath: string, public onVideoComplete : () => void){

    }

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    }


    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
        this.imageTextureTask = assetsManager.addTextureTask("totumImageTask", this.imagePath, false, false);

    }


    public OnAssetLoadComplete () {

        //Get Display Mesh
        let aMesh: BABYLON.Nullable<BABYLON.AbstractMesh> = this.scene.getMeshByName(this.meshName); 
        
        if(aMesh != null && aMesh instanceof Mesh)
        {
            this.displayMesh = aMesh;
        }else{
            console.log("Could not find Totum's mesh at " + this.meshName);
            return;
        }
        

        //Get Loaded image texture
        this.imageMaterial = new StandardMaterial("totumImageMat", this.scene);
        this.imageMaterial.diffuseTexture = this.imageTextureTask.texture;    
        this.imageMaterial.emissiveColor = new BABYLON.Color3(1,1,1);

        //Set mesh's texture
        this.displayMesh.material = this.imageMaterial;

        
        //Get Video Data
        let videoTextureSettings : VideoTextureSettings = {
            loop: false,
            autoPlay: false,
            autoUpdateTexture: true,
            poster: this.imagePath
        };

        this.videoTexture = new BABYLON.VideoTexture("totumVideo", this.videoPath, this.scene, true, true, BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE, videoTextureSettings);
        this.videoTexture.video.preload = "auto";

        this.videoMaterial = new StandardMaterial("totumVideoMat", this.scene);
        this.videoMaterial.diffuseTexture = this.videoTexture;
        this.videoMaterial.emissiveColor = new BABYLON.Color3(1,1,1);

        //this.videoTexture.video.preload = 'none';
        this.videoTexture.video.onerror = ((event)=>{
            console.log("Video error");
            console.log(event);
        });

        this.videoTexture.video.onplay =(()=>{            
            this.displayMesh.material = this.videoMaterial;
        })
        

        
        
        aMesh.actionManager = new BABYLON.ActionManager(this.scene);
        aMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{    

            this.playVideo();            
            
        }));   
           
         
    }
    

    public Update () {

       
        if(!this.isPlaying) return;
        
    
        if(this.camera.position.z < -6){
        //if(BABYLON.Vector3.Distance(this.camera.position, this.displayMesh.position) > 20){
           
            if(this.isPlaying && this.videoTexture != null){
             
               this.stopVideo();
            }
        }
        //}

    }

    playVideo = () => {

        this.videoTexture.video.addEventListener("error", () => {
            console.log("Errror");
        }, true);

                
        BABYLON.VideoTexture.WhenAllReady([this.videoTexture], ()=>{          
            this.doPlayVideo()
        });
      
    }

    doPlayVideo  = () => {

        
        
          //Start video
          let promise = this.videoTexture.video.play();
          promise.then(function () {
                console.log("Video Promise Resolved");
            }).catch(function () {
                console.log(promise);
                    console.log("Video Promise Rejected");
            });


            console.log("Play B");
          //this.displayMesh.material = this.videoMaterial;
          this.isPlaying = true;
          
  
          if(this.playedOnce){
              this.videoTexture.video.addEventListener("ended", () => {
                  this.stopVideo();
              }, true);
          }
  
          this.playedOnce = true;
    }

    stopVideo = () => {
         //Stop video
         this.videoTexture.video.pause();
         this.videoTexture.video.currentTime = 0;
         this.displayMesh.material = this.imageMaterial;
         this.isPlaying = false;

         this.onVideoComplete();
    }
    

}
  
    



