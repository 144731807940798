import * as BABYLON from 'babylonjs';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, PBRMaterial } from 'babylonjs';



export class MainGallery implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
    mainGalleryTask : BABYLON.MeshAssetTask;
    sky : BABYLON.MeshAssetTask;
    feetTextureTask : BABYLON.TextureAssetTask;
    feetGraphic : BABYLON.Mesh;

    groundMesh : BABYLON.Mesh;
    feet : BABYLON.Mesh;
    isAnimating : boolean = false;
    canWalk : boolean = false;
    TWO_PI = Math.PI * 2;

    walkMusicTask : BABYLON.BinaryFileAssetTask;
    walkSound : BABYLON.Sound;

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;

        

        //Basic Camera movement and Physics
        this.camera.checkCollisions = true;
        camera.applyGravity = true;
        camera.ellipsoid = new BABYLON.Vector3(2, 1, 2);

        this.scene.gravity = new BABYLON.Vector3(0, -0.9, 0);    
        this.scene.collisionsEnabled = true;

        //This creates a light, aiming 0,1,0 - to the sky (non-mesh)
        //var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), this.scene);
        
       // camera.onCollide = this.onCameraCollision;
     
    }

    

    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
        this.mainGalleryTask = assetsManager.addMeshTask("MainGalleryTask", "", "./assets/models/", "Exhibit 4-29 C.glb");
        this.sky = assetsManager.addMeshTask("SkyTask", "", "./assets/models/", "BakedHDR 4-29 C.glb");
       
        this.feetTextureTask = assetsManager.addTextureTask("feetImageTask", "./assets/textures/walk.png");

        this.walkMusicTask = assetsManager.addBinaryFileTask("walkMusicTask", "./assets/sounds/Vibrant Slick Click.mp3");
    }

    public OnAssetLoadComplete () {

        //Sound
        this.walkSound = new BABYLON.Sound("walkSound", this.walkMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });


               
        //Setup walking around

        let skyMeshes = this.sky.loadedMeshes;
        if(skyMeshes!=null){
            for(var i=0;i<skyMeshes.length;i++) {
                skyMeshes[i].checkCollisions = false;
                skyMeshes[i].isPickable = false;
            }
        } else {
            console.warn("Sky Meshes missing");
        }
        
        let meshes = this.mainGalleryTask.loadedMeshes;
        if(meshes != null){

            //Locate the ground
            for(var i=0;i<meshes.length;i++) {
                meshes[i].checkCollisions = true;
                
                /*
                if(meshes[i].material instanceof BABYLON.PBRMaterial){
                    let mat : BABYLON.PBRMaterial = meshes[i].material as PBRMaterial;
                    mat.reflectionColor = new BABYLON.Color3(0,0,0);
                }
                */

                if(meshes[i].name == "Building.002_primitive3"){
                    this.groundMesh = meshes[i] as BABYLON.Mesh;
                    this.groundMesh.actionManager = new BABYLON.ActionManager(this.scene);
                } 
            }

            


            //this.EnableWalkAround();




            /*
            var cameraCollider = BABYLON.Mesh.CreateSphere("cameraCollider", 1, 1, this.scene);
            cameraCollider.parent = this.camera;
            cameraCollider.isVisible = false;
            cameraCollider.actionManager = new BABYLON.ActionManager(this.scene);
            let action = new BABYLON.ExecuteCodeAction(
                {
                    trigger: BABYLON.ActionManager.OnIntersectionEnterTrigger, 
                    parameter: { 
                        mesh: this.feet
                    }
                },
                (evt) => {
                    console.log("AAAAAAAAAAAAAAAAAAAAAAAAA");
                    //totum.material = totumVideoMaterial;
                    
                }
            );
            cameraCollider.actionManager.registerAction(action);
            */

        }


    }
    
    public Update () {

    }

    public EnableWalkAround (){

        //Create feet cursor
        this.feet =  BABYLON.Mesh.CreateBox("Feet", 1, this.scene);
        this.feet.isPickable = false;
        this.feet.rotationQuaternion = null;
        this.feet.isVisible = false;

        this.feetGraphic = BABYLON.Mesh.CreatePlane("FeetGraphic", 1, this.scene);
        this.feetGraphic.parent = this.feet;
        this.feetGraphic.position = new BABYLON.Vector3(0, 0, 0);
        this.feetGraphic.isPickable = false;

        let feetMaterial : StandardMaterial = new BABYLON.StandardMaterial("impactMat",this.scene);
        feetMaterial.diffuseTexture = this.feetTextureTask.texture;
        feetMaterial.diffuseTexture.hasAlpha = true;
        feetMaterial.emissiveColor = BABYLON.Color3.White();            
        this.feetGraphic.material = feetMaterial;            
        
        this.feetGraphic.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD);


        this.canWalk = true;

        if(this.groundMesh != null && this.groundMesh.actionManager!= null){
            this.groundMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{     

                BABYLON.Engine.audioEngine.unlock();
            
                let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
            
                if (pickResult && pickResult.hit) {
                    if(pickResult.pickedPoint != null){
                        if(!this.isAnimating){

                            this.isAnimating = true;

                            let allowedPosition = this.getFeetPosition(pickResult.pickedPoint.x, pickResult.pickedPoint.z);

                            //Position
                            let fromPosition = new BABYLON.Vector3(this.camera.position.x, this.camera.position.y, this.camera.position.z);
                            let toPosition = new BABYLON.Vector3(allowedPosition.x, this.camera.position.y, allowedPosition.y);    
                            //let toPosition = new BABYLON.Vector3(pickResult.pickedPoint.x, this.camera.position.y, pickResult.pickedPoint.z);    

                            BABYLON.Animation.CreateAndStartAnimation('cameraPositionAnimation', this.camera, 'position', 30, 30, fromPosition, toPosition, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, undefined, ()=>{
                                this.isAnimating=false;    
                            });
                           

                            //Animate rotation
                            let angle : number = Math.atan2( toPosition.x - fromPosition.x, this.feet.position.z - fromPosition.z);    
                           
                            //console.log("Angle " + angle);
                            let delta = angle - this.camera.rotation.y;
                            //console.log("camera " + this.camera.rotation.y);
                            //console.log("delta " + delta);

                            //Limit rotation to 180 degrees max
                            while(Math.abs(delta) > Math.PI){
                                if(delta > 0){
                                    angle -= this.TWO_PI;                                    
                                } else {
                                    angle += this.TWO_PI;
                                }
                                delta = angle - this.camera.rotation.y;
                            }
                            
                            BABYLON.Animation.CreateAndStartAnimation('cameraRotationAnimation', this.camera, 'rotation.y', 30, 30, this.camera.rotation.y, angle, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

                            this.updateFeetPosition(toPosition.x, toPosition.z, angle);

                            this.walkSound.play();

                        }
                    }
                }
            }));


            this.scene.onPointerObservable.add(this.onPointerObservable);

        }
            
    }


    getFeetPosition = (x : number, z:number) : BABYLON.Vector2 =>{

        let distanceOffWall = 5;

        let from = new BABYLON.Vector3(this.camera.position.x, 0.05,this.camera.position.z);
        let to = new BABYLON.Vector3(x, 0.05,z);

        let from2D = new BABYLON.Vector2(this.camera.position.x, this.camera.position.z);
        let to2D = new BABYLON.Vector2(x, z);

        let requestedDistance = BABYLON.Vector2.Distance(from2D, to2D);

        //Find the nearest collision
        let rayDirection = to.subtract(from);
        rayDirection = BABYLON.Vector3.Normalize(rayDirection);

        let ray = new BABYLON.Ray(from, rayDirection, 9999);
        let hit = this.scene.pickWithRay(ray);
        if (hit && hit.hit && hit.pickedPoint != null) {
            
            let hit2d = new  BABYLON.Vector2(hit.pickedPoint.x, hit.pickedPoint.z);
            let hitDistance = BABYLON.Vector2.Distance(from2D, hit2d);

            let maxAllowedDistance = hitDistance - distanceOffWall;
           
            //Check if we are too close to a wall
            if(hitDistance < distanceOffWall || requestedDistance > maxAllowedDistance){
                let allowDistance = hitDistance - distanceOffWall;    

                let deltaVector = rayDirection.multiply(new BABYLON.Vector3(allowDistance,0,allowDistance));
                let adjustedFootPosition = from.add(deltaVector);
                return new BABYLON.Vector2(adjustedFootPosition.x, adjustedFootPosition.z);

            } else {
                return new BABYLON.Vector2(x, z);
            }


        } else {
            return new BABYLON.Vector2(x, z);
        }

    }

    /*
    onCameraCollision = (collidedMesh: BABYLON.AbstractMesh) =>{
        
        if(this.canWalk){

            console.log('A');
            if(this.isAnimating){
                console.log('C');
                if(collidedMesh != this.groundMesh){
                    console.log("STOP");
                    this.scene.stopAnimation(this.camera);
                }
            }
        } else {
            console.log('B');
        }
    }
    */

    onPointerObservable = (pointerInfo : BABYLON.PointerInfo) =>{
        
        switch (pointerInfo.type) {
            case BABYLON.PointerEventTypes.POINTERDOWN:
                //console.log("POINTER DOWN");
                break;
            case BABYLON.PointerEventTypes.POINTERUP:
                //console.log("POINTER UP");
                break;
            case BABYLON.PointerEventTypes.POINTERMOVE:
                //console.log("POINTER MOVE");
                //console.log(this.scene);
                
                let pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);

                
                //
        
                if (pickResult && pickResult.hit) {

                    if(pickResult.pickedMesh == this.groundMesh){
                        this.feetGraphic.isVisible = true;
                    } else {
                        this.feetGraphic.isVisible = false;
                    }

                    if(pickResult.pickedPoint != null){                        
                        let angle : number = Math.atan2( this.feet.position.x - this.camera.position.x, this.feet.position.z - this.camera.position.z);                        
                        this.feet.rotation.y = angle;   


                        let feetPosition = this.getFeetPosition(pickResult.pickedPoint.x, pickResult.pickedPoint.z);
                        this.updateFeetPosition(feetPosition.x,feetPosition.y, angle);
                        
                        //this.updateFeetPosition(pickResult.pickedPoint.x, pickResult.pickedPoint.z, angle);
                    }
                }
                

                break;
            case BABYLON.PointerEventTypes.POINTERWHEEL:
                //console.log("POINTER WHEEL");
                break;
            case BABYLON.PointerEventTypes.POINTERPICK:
                //console.log("POINTER PICK");
                break;
            case BABYLON.PointerEventTypes.POINTERTAP:
                //console.log("POINTER TAP");
                

                break;
            case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
                //console.log("POINTER DOUBLE-TAP");




                break;
        }
    }

    updateFeetPosition = (x : number, z : number, angle : number) => {

        this.feet.position.x = x;
        this.feet.position.y = 0.02;
        this.feet.position.z = z;

        this.feet.rotation.y = angle;

    }


}

