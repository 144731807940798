import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, Vector3, Mesh, Material } from 'babylonjs';
import { throws } from 'assert';
import { timingSafeEqual } from 'crypto';



export class TotumExhibit implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
    videoPath: string;
    imagePath: string;
    meshName: string;
    videoTextureTask: BABYLON.TextureAssetTask;
    videoTexture: BABYLON.VideoTexture;
    videoMaterial: BABYLON.StandardMaterial;
    imageTextureTask: BABYLON.TextureAssetTask;
    imageMaterial: BABYLON.StandardMaterial;
    displayMesh: BABYLON.Mesh;

    instructionMaterial: BABYLON.StandardMaterial;

    instructionsTextureTask: BABYLON.TextureAssetTask;

    // Activation
    inRange: boolean;


    constructor(mesh_name: string, video_path: string, image_path: string){
        this.videoPath = video_path;
        this.meshName = mesh_name;
        this.imagePath = image_path;
    }
   
    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    } 

    public LoadAssets (assetsManager : BABYLON.AssetsManager)  { 
        
        this.imageTextureTask = assetsManager.addTextureTask("totumImageTask", this.imagePath, false);
        this.instructionsTextureTask = assetsManager.addTextureTask("totumImageTask", "./assets//textures/totem-info.png", false);

        this.imageTextureTask.onError =  (task: BABYLON.TextureAssetTask, message: string | undefined) => { 
            console.log("Error Loading Totum's Image Texture"); 
            console.error(message); 
        } 
        
        
    } 
           
           
    public OnAssetLoadComplete () { 


        //Get Display Mesh
        let aMesh: BABYLON.Nullable<BABYLON.AbstractMesh> = this.scene.getMeshByName(this.meshName); 
        if(aMesh != null && aMesh instanceof Mesh)
        {
            this.displayMesh = aMesh;
        }else{
            console.log("Could not find Totum's mesh at " + this.meshName);
            return;
        }

        //Get Video Data
        let videoTextureSettings = {
            loop: false,
            autoPlay: false,
            autoUpdateTexture: true
        };
        this.videoTexture = new BABYLON.VideoTexture("totumVideo", this.videoPath, this.scene, true, false, BABYLON.VideoTexture.TRILINEAR_SAMPLINGMODE, videoTextureSettings);
        this.videoMaterial = new StandardMaterial("totumVideoMat", this.scene);
        this.videoMaterial.diffuseTexture = this.videoTexture;
        this.videoMaterial.emissiveColor = new BABYLON.Color3(1,1,1);

        //Get Loaded image texture
        this.imageMaterial = new StandardMaterial("totumImageMat", this.scene);
        this.imageMaterial.diffuseTexture = this.imageTextureTask.texture;    
        this.imageMaterial.emissiveColor = new BABYLON.Color3(1,1,1);

        //Set mesh's texture
        this.displayMesh.material = this.imageMaterial;



        let totumIntructions : BABYLON.Mesh = BABYLON.MeshBuilder.CreatePlane("totumMesh", {width: 1.5, height: 1.5}, this.scene);
        totumIntructions.position = new BABYLON.Vector3(3, 0, 0);
        totumIntructions.parent = aMesh;
        totumIntructions.name = "TotumInstructions"
      
        this.instructionMaterial = new StandardMaterial("totumInstructionsMat", this.scene);
        this.instructionMaterial.diffuseTexture = this.instructionsTextureTask.texture;    
        this.instructionMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
        this.instructionMaterial.diffuseTexture.hasAlpha = true;
        this.instructionMaterial.useAlphaFromDiffuseTexture = true;
        totumIntructions.material = this.instructionMaterial;


    }
    
    public Update () {
        if(BABYLON.Vector3.Distance(this.camera.position, this.displayMesh.position) < 10){
            if(!this.inRange && this.videoTexture != null){


                //Start video
                this.videoTexture.video.play();
                this.displayMesh.material = this.videoMaterial;
                this.inRange = true;
            }
        }else{
            if(this.inRange && this.videoTexture != null){
                //Stop video
                this.videoTexture.video.pause();
                this.videoTexture.video.currentTime = 0;
                this.displayMesh.material = this.imageMaterial;
                this.inRange = false;
            }
        }
    }

}

