import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { PromisePolyfill } from 'babylonjs';
import {ImageCarouselAsset} from '../Scene/PageWithScene';

interface Props {
    onHide: () => void,
    show : boolean,
    title : string,
    subTitle : string,
    text : string,
    imageCarouselAssets : ImageCarouselAsset[]
}

interface State {
 
};


class TitleTextAndImageCarouselModal extends React.Component<Props,State>{
    
    state = {activeSlide : 0};

    handleSelect = (selectedIndex : number) => {
        this.setState({activeSlide : selectedIndex});
    };
    //activeIndex={this.state.activeSlide} onSelect={this.handleSelect}

    render(){

        let carouselItems = null;
        
        carouselItems = this.props.imageCarouselAssets.map( (imageCarouselAsset : ImageCarouselAsset, index : number) =>{
            return (
                <Carousel.Item key={index}>
                    <img className="d-block w-100" src={imageCarouselAsset.imagePath} alt="Slide"  />
                    
                </Carousel.Item>
            ) 
        });
        

        return (

            <Modal show={this.props.show} onHide={this.props.onHide} size="lg"  aria-labelledby="contained-modal-title-vcenter" centered>

                <Modal.Body>
                    <Carousel indicators={false} interval={null}  wrap={false} >
                        {carouselItems}
                    </Carousel>
                                
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
           
        )
    }
  }





  export default TitleTextAndImageCarouselModal;

/*

<Carousel.Caption>
                        <h3>{imageCarouselAsset.title}</h3>
                        <p>{imageCarouselAsset.text}</p>
                    </Carousel.Caption>

/*
<Carousel indicators={false} interval={null}>
                        {carouselItems}                    
                    </Carousel>
                    */


                    /*
                    <Modal show={this.props.show} size="lg"  aria-labelledby="contained-modal-title-vcenter" centered>

                

                <Modal.Body>
                    <Carousel>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="./assets/textures/goose0.jpg"
                            alt="First slide"
                            />
                            <Carousel.Caption>
                            <h3>First slide label</h3>
                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="./assets/textures/goose1.jpeg"
                            alt="Third slide"
                            />

                            <Carousel.Caption>
                            <h3>Second slide label</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="d-block w-100"
                            src="./assets/textures/goose2.jpg"
                            alt="Third slide"
                            />

                            <Carousel.Caption>
                            <h3>Third slide label</h3>
                            <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                                
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            */