import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, Vector3, Color3, Engine } from 'babylonjs';
import { throws } from 'assert';



export class Pinger implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties
    anchor : BABYLON.TransformNode; 
    pingerTextureTask : BABYLON.TextureAssetTask;
    pingerMeshes : BABYLON.Mesh[] = [];
   
    frames = 180;
    alpha = 1;
    rings = 4;

    pingSelectMusicTask : BABYLON.BinaryFileAssetTask;
    pingSelectSound : BABYLON.Sound;

    
    constructor(public name:string, public position : Vector3, public rotation : Vector3, public radius : number, public isPickable : boolean, public onSelectionCallback : ()=>void){

    }

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    }


    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
        this.pingSelectMusicTask = assetsManager.addBinaryFileTask("Pinger Sound task", "./assets/sounds/Chirp UI 1.mp3");
        //this.pingerTextureTask = assetsManager.addTextureTask('pingerTextureTask', './assets/textures/ping-still.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE);   
    }


    public OnAssetLoadComplete () {
        
        //Sound
        this.pingSelectSound = new BABYLON.Sound("triviaCorrectSound", this.pingSelectMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });

        setTimeout( ()=>{

            this.anchor = new BABYLON.TransformNode("pingerAnchor"); 
            this.anchor.name = this.name;
            this.anchor.position = this.position;
            this.anchor.rotation = this.rotation;
            //this.anchor.position = new BABYLON.Vector3(0, 0.25, 0);
            //this.anchor.rotate(BABYLON.Axis.X, Math.PI / 2, BABYLON.Space.WORLD);
            
            let materials : StandardMaterial[] = [];


            for(let i=0; i < this.rings; ++i){
                let pingerMesh = BABYLON.MeshBuilder.CreateDisc("pinger_ring_"+i, {radius : this.radius, tessellation: 50}, this.scene); // makes a triangle
                //let pingerMesh = BABYLON.Mesh.CreatePlane("FeetGraphic", 1, this.scene);
                pingerMesh.parent = this.anchor;
                pingerMesh.isPickable = this.isPickable;
                //pingerMesh.visibility = 0.9999;
                //pingerMesh.blend
                
        
                let pingerMaterial : StandardMaterial = new BABYLON.StandardMaterial("pingerMaterial",this.scene);
                //pingerMaterial.diffuseTexture = this.pingerTextureTask.texture;
                //pingerMaterial.diffuseTexture.hasAlpha = true;
                pingerMaterial.emissiveColor = Color3.White();         
                pingerMesh.material = pingerMaterial;            
            
                
                pingerMesh.scaling = Vector3.Zero();
                pingerMaterial.alpha = 0.2;

                this.pingerMeshes[i] = pingerMesh;           
                
                materials[i] = pingerMaterial;   
                
                
                if(this.isPickable){
                        
                    pingerMesh.actionManager = new BABYLON.ActionManager(this.scene);
                    pingerMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{
                        this.pingSelectSound.play();
                        this.onSelectionCallback();                            
                    }));
                        
                    
                }
                
            }      


            let animationDuration : number[] = [];
            let scaling : Vector3 [] = [];
            let alphaArray : number [] = [];

            let frameStep = this.frames / this.rings;
            let scalingStep = 1 / this.rings;
            let alphaStep = this.alpha / this.rings;

            for(let i=0; i < this.rings; ++i){
                animationDuration[i] = this.frames - frameStep * i;              
                let scaleVal = i * scalingStep;
                scaling[i] = new Vector3(scaleVal,scaleVal,scaleVal);
                alphaArray[i] = this.alpha - alphaStep * i;
            }

            for(let i=0; i < this.rings; ++i){

                this.pingerMeshes[i].scaling = scaling[i];           
                materials[i].alpha = alphaArray[i];            
                
                BABYLON.Animation.CreateAndStartAnimation('pingerScale_' + i, this.pingerMeshes[i], 'scaling', 60, animationDuration[i], scaling[i], BABYLON.Vector3.One(), BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, undefined, ()=>{
                    this.pingMesh(this.pingerMeshes[i]);
                });
                
                BABYLON.Animation.CreateAndStartAnimation('questionHideAnimation', this.pingerMeshes[i], 'material.alpha', 60, animationDuration[i], alphaArray[i], 0, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,undefined,()=>{ 
            
                });
                
            }      


            


        }, Math.random() * 300);          

        
         
    }
    
    public Update () {
    
    }

    pingMesh = (mesh:BABYLON.Mesh) => {
        BABYLON.Animation.CreateAndStartAnimation('pingerScale', mesh, 'scaling', 60, this.frames, BABYLON.Vector3.Zero(), BABYLON.Vector3.One(), BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE, undefined, ()=>{
            this.pingMesh(mesh);
        });
        BABYLON.Animation.CreateAndStartAnimation('pingerAlpha', mesh, 'material.alpha', 60, this.frames, this.alpha, 0, BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE,undefined,()=>{});
    }


    

}
  
    



