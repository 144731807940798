import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { PromisePolyfill } from 'babylonjs';

interface Props {
    onHide: () => void,
    show : boolean,
    title : string,
    subTitle : string,
    text : string
}

interface State {
 
};


class TitleAndTextModal extends React.Component<Props,State>{
    

    render(){

        return (
            <Modal
                show={this.props.show} onHide={this.props.onHide} 
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {this.props.title}
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <h4>{this.props.subTitle}</h4>
                <p>
                    {this.props.text}
                </p>
                
                                
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
            );
        }
  }


  export default TitleAndTextModal;

