import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { PromisePolyfill } from 'babylonjs';

interface Props {
    onHide: () => void,
    show : boolean,
    title : string,
    subTitle : string,
    text : string,
    video : string
}

interface State {
 
};


class TitleAndVideoModal extends React.Component<Props,State>{
    

    render(){

        return (
            <Modal show={this.props.show} onHide={this.props.onHide} size="lg" aria-labelledby="contained-modal-title-vcenter"  centered>
               
                <Modal.Body>               
                
                    <div className="embed-responsive embed-responsive-16by9">
                        <iframe className="embed-responsive-item" src={this.props.video} allowFullScreen></iframe>
                    </div>
                                
                </Modal.Body>

                <Modal.Footer>
                <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
                
            </Modal>
            );
        }
  }


  export default TitleAndVideoModal;

