import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, Vector3 } from 'babylonjs';
import { CustomMaterial, PBRCustomMaterial } from 'babylonjs-materials';



export class ScrollingTextureExhibit implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties

    scrollingTextureTask : BABYLON.TextureAssetTask;
    scrollingMesh : BABYLON.Mesh;
    scrollingMesh2 : BABYLON.Mesh;
    scrollingMeshMaterial : StandardMaterial;
    scrollTexture : BABYLON.Texture;

    offset = 0;

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    }

    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
        this.scrollingTextureTask = assetsManager.addTextureTask('scrollingTextureTask', './assets/textures/Trivia/leaderboard-01-trans.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE);    
    }

    public OnAssetLoadComplete () {
        //var light = new BABYLON.HemisphericLight("light1", new BABYLON.Vector3(0, 1, 0), this.scene);
        //this.scrollingMesh = BABYLON.Mesh.CreateSphere("a1", 18, 4, this.scene);

        let z = -40.5;
        let h = 6.5;

        this.scrollingMesh = BABYLON.MeshBuilder.CreatePlane("scrollingMesh", {width:h/2, height:h}, this.scene);
        this.scrollingMesh.rotation.y = Math.PI;
        this.scrollingMesh.position = new BABYLON.Vector3(7.5, h/2, z);
        this.scrollingMesh.name = "Leaderboard1";

        this.scrollingMesh2 = BABYLON.MeshBuilder.CreatePlane("scrollingMesh", {width:h/2, height:h}, this.scene);
        this.scrollingMesh2.rotation.y = Math.PI;
        this.scrollingMesh2.position = new BABYLON.Vector3(-7.5, h/2, z);
        this.scrollingMesh.name = "Leaderboard2";

        this.scrollingMeshMaterial  = new BABYLON.StandardMaterial("scrollingMeshMaterial", this.scene);  
        this.scrollTexture =  this.scrollingTextureTask.texture; 
        this.scrollingMeshMaterial.diffuseTexture = this.scrollTexture;
        this.scrollingMeshMaterial.diffuseTexture.hasAlpha = true;
        this.scrollingMeshMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        this.scrollingMeshMaterial.useAlphaFromDiffuseTexture = true;

        this.scrollingMesh.material = this.scrollingMeshMaterial;
        this.scrollingMesh2.material = this.scrollingMeshMaterial;

       
    }
    
    public Update () {
        
        this.scrollTexture.vOffset -= 0.001;
        

    }

    

}



/*


 var material : CustomMaterial = new CustomMaterial("name", this.scene);  
        
        //CustomMaterial.EmissiveTextureEnabled = true;

        material.AddUniform('u_tex', 'sampler2D',{});
        material.AddUniform('u_time', 'float',{});
        material.AddUniform('u_resolution', 'vec2',{});
        //material.emissiveColor = BABYLON.Color3.White();
        material.emissiveTexture = this.scrollingTextureTask.texture;
        //material.useEmissiveAsIllumination = true;
        material.linkEmissiveWithDiffuse = true;
        material.diffuseColor = Color3.Black();
        material.ambientColor = Color3.Black();

       
        
        var u_resolution = new BABYLON.Vector2(512, 410);
        var u_time = 0;

        material.onBindObservable.add( () => {

            u_resolution = new BABYLON.Vector2(512, 410);

            material.getEffect().setVector2('u_resolution', u_resolution);

            material.getEffect().setTexture('u_tex', this.scrollingTextureTask.texture);

            //u_time++;
            u_time += 0.3;

            if (material && material.getEffect && material.getEffect()) {
                material.getEffect().setFloat('u_time', u_time);
            }

        });

        material.Fragment_Custom_Diffuse('\
            float t = gl_FragCoord.y;\
            t -= u_time;  ;\
            result = texture2D(u_tex, vec2(gl_FragCoord.x / u_resolution.x, t  / u_resolution.y)).rgb;\
        ');


        this.scrollingMesh.material = material;



        */