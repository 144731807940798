import * as BABYLON from 'babylonjs';
import * as GUI from 'babylonjs-gui';
import {SceneEventArgs} from '../Scene';
import NSMSceneBehavior from '../NSMSceneBehavior';

import { StandardMaterial, Vector3 } from 'babylonjs';
import { throws } from 'assert';



export class TriviaExhibit implements NSMSceneBehavior{

    //NSMSceneBehavior Interface
    engine : BABYLON.Engine;
    scene : BABYLON.Scene;
    canvas : HTMLCanvasElement;
    camera : BABYLON.FreeCamera;

    //Class properties

    buttonImagePaths : string[] = ['trivia-A-01-CES.png', 'trivia-A-01-CONEXPO.png', 'trivia-A-01-E3.png', 'trivia-A-01-HIMMS.png'];
    buttonTextureTask : BABYLON.TextureAssetTask[] = [];
    titleTextureTask : BABYLON.TextureAssetTask;
    questionTextureTask : BABYLON.TextureAssetTask;
    correctTextureTask : BABYLON.TextureAssetTask;
    incorrectTextureTask : BABYLON.TextureAssetTask;

    correctMusicTask : BABYLON.BinaryFileAssetTask;
    incorrectMusicTask : BABYLON.BinaryFileAssetTask;
    hoverMusicTask : BABYLON.BinaryFileAssetTask;

    correctSound : BABYLON.Sound;
    incorrectSound : BABYLON.Sound;
    hoverSound : BABYLON.Sound;

    anchor : BABYLON.AbstractMesh;
    buttons : BABYLON.Mesh[] = [];

    question : BABYLON.Mesh;
    correctIncorrect : BABYLON.Mesh;
    correctIncorrectMaterial : BABYLON.StandardMaterial

    public Start(sceneEventArgs: SceneEventArgs, camera : BABYLON.FreeCamera){

        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
       
    }

    public LoadAssets (assetsManager : BABYLON.AssetsManager)  {
        this.titleTextureTask = assetsManager.addTextureTask('titleTextureTask', './assets/textures/Trivia/trivia-title.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE);
        this.questionTextureTask = assetsManager.addTextureTask('questionTextureTask', './assets/textures/Trivia/trivia-Q-01-text.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE);
        this.correctTextureTask = assetsManager.addTextureTask('correctTextureTask', './assets/textures/Trivia/trivia-C-01.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE);
        this.incorrectTextureTask = assetsManager.addTextureTask('incorrectTextureTask' , './assets/textures/Trivia/trivia-IC-01.png', false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE);

        
        for(let i=0; i<this.buttonImagePaths.length; ++i){
            this.buttonTextureTask.push(assetsManager.addTextureTask('buttonTexture_' + i , './assets/textures/Trivia/' + this.buttonImagePaths[i], false, true, BABYLON.Texture.TRILINEAR_SAMPLINGMODE));
        }

        this.correctMusicTask = assetsManager.addBinaryFileTask("correctMusicTask", "./assets/sounds/Vibrant Game Correct Answer Hit.mp3");
        this.incorrectMusicTask = assetsManager.addBinaryFileTask("incorrectMusicTask", "./assets/sounds/Vibrant Game Negative Alert.mp3");
        this.hoverMusicTask = assetsManager.addBinaryFileTask("hoverMusicTask", "./assets/sounds/Vibrant Accept Button.mp3");
        
    }

    public OnAssetLoadComplete () {

        //Sounds
        this.correctSound = new BABYLON.Sound("triviaCorrectSound", this.correctMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });
        this.incorrectSound = new BABYLON.Sound("triviaIncorrectSound", this.incorrectMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });
        this.hoverSound = new BABYLON.Sound("triviaHoverSound", this.hoverMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });

        
        this.anchor = new BABYLON.AbstractMesh("triviaAnchor", this.scene);
        this.anchor.position = new BABYLON.Vector3(0, 1, -40.5);
        this.anchor.rotationQuaternion = null;
        this.anchor.rotation.y = Math.PI;


        //let virusArt = this.scene.getMeshByName('Art07') as BABYLON.Mesh;
        //virusArt.isVisible = false;

        let z = 0;

        //Add the title
        let titleMesh = BABYLON.MeshBuilder.CreatePlane("trivia_triviaTitleMesh", {width: 7.1, height:0.7}, this.scene);
        titleMesh.parent = this.anchor;
        titleMesh.position = new Vector3(0, 4.5, z);

        let titleMeshMaterial : StandardMaterial = new BABYLON.StandardMaterial("trivia_titleMeshMaterial", this.scene);    
        titleMeshMaterial.diffuseTexture = this.titleTextureTask.texture;
        titleMeshMaterial.diffuseTexture.hasAlpha = true;
        titleMeshMaterial.emissiveColor = new BABYLON.Color3(1, 1, 1);
        titleMesh.material = titleMeshMaterial;
        titleMeshMaterial.useAlphaFromDiffuseTexture = true;

        //Question
        this.question = BABYLON.MeshBuilder.CreatePlane("trivia_questionMesh", {width: 10.24 , height:1.6}, this.scene);
        this.question.parent = this.anchor;
        this.question.position = new Vector3(0, 2.5 ,z);

        let questionMaterial : StandardMaterial = new BABYLON.StandardMaterial("trivia_questionMaterial", this.scene);    
        questionMaterial.diffuseTexture = this.questionTextureTask.texture;
        questionMaterial.diffuseTexture.hasAlpha = true;
        questionMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
        this.question.material = questionMaterial;
        questionMaterial.useAlphaFromDiffuseTexture = true;

        //Correct / Incorrect
        this.correctIncorrect = BABYLON.MeshBuilder.CreatePlane("trivia_questionMesh", {width: 10.24 , height:3.4}, this.scene);
        this.correctIncorrect.parent = this.anchor;
        this.correctIncorrect.position = new Vector3(0, 2.0 ,z);
        this.correctIncorrect.isVisible = false;

        this.correctIncorrectMaterial = new BABYLON.StandardMaterial("trivia_questionMaterial", this.scene);    
        this.correctIncorrectMaterial.diffuseTexture = this.correctTextureTask.texture;
        this.correctIncorrectMaterial.diffuseTexture.hasAlpha = true;
        this.correctIncorrectMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
        this.correctIncorrect.material = this.correctIncorrectMaterial;
        this.correctIncorrectMaterial.useAlphaFromDiffuseTexture = true;


        //Add the buttons
        for(let i=0; i<this.buttonImagePaths.length; ++i){
            
            let buttonMesh = BABYLON.MeshBuilder.CreatePlane("triviaButtonPlaneMesh_" + i, {size: 2} ,this.scene);
            buttonMesh.parent = this.anchor;
            buttonMesh.position = new Vector3(0,1,0);
            this.buttons.push(buttonMesh);

             //Create materials
             let buttonMaterial : StandardMaterial = new BABYLON.StandardMaterial("triviaButtonMaterial_" + i, this.scene);    
             buttonMaterial.diffuseTexture = this.buttonTextureTask[i].texture;
             buttonMaterial.diffuseTexture.hasAlpha = true;
             buttonMaterial.emissiveColor = new BABYLON.Color3(1,1,1);
             buttonMesh.material = buttonMaterial;
             buttonMaterial.useAlphaFromDiffuseTexture = true;

            buttonMesh.actionManager = new BABYLON.ActionManager(this.scene);

            //Make buttons larger when hovered
            buttonMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, ()=>{
                if(this.buttonSelectionLocked) return;
                buttonMesh.scaling =  new BABYLON.Vector3(1.2, 1.2, 1.2);
                this.hoverSound.play();
            }));

            buttonMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, ()=>{
                if(this.buttonSelectionLocked) return;
                buttonMesh.scaling =  BABYLON.Vector3.One();
            }));

            buttonMesh.actionManager.registerAction(new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPickTrigger, ()=>{
                if(this.buttonSelectionLocked) return;
                this.onButtonSelected(i);
            }));

        }

        let y = 0.5;
        
        this.buttons[0].position = new Vector3(-3.75, y, z);
        this.buttons[1].position = new Vector3(-1.25, y,z);
        this.buttons[2].position = new Vector3(1.25, y, z);
        this.buttons[3].position = new Vector3(3.75, y, z);

    }
    
    public Update () {
    
    }


    buttonSelectionLocked : boolean = false;
    onButtonSelected = (index : number) => {

        if(this.buttonSelectionLocked) return;
        this.buttonSelectionLocked = true;

        


        let easingFunction = new BABYLON.ExponentialEase();
        easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        for(let i=0; i < this.buttons.length; ++i){
            let button = this.buttons[i];
            if(i == index){
                //They picked this response
                if(index != 0){
                    this.incorrectSound.play();
                } else {
                    this.correctSound.play();
                }


                setTimeout(()=>{
                   
                    BABYLON.Animation.CreateAndStartAnimation('questionHideAnimation', this.question, 'scaling', 60, 15, BABYLON.Vector3.One(), BABYLON.Vector3.Zero(), BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction);

                    BABYLON.Animation.CreateAndStartAnimation('buttonHideAnimation_' + i, button, 'scaling', 60, 15, new BABYLON.Vector3(1.2, 1.2, 1.2), BABYLON.Vector3.Zero(), BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction, ()=>{
                        this.question.isVisible = false;
                        this.correctIncorrect.isVisible = true;
    
                        if(index != 0){
                           
                            this.correctIncorrectMaterial.diffuseTexture = this.incorrectTextureTask.texture;
                            this.correctIncorrectMaterial.diffuseTexture.hasAlpha = true;
                        } else {                            
                            
                            this.correctIncorrectMaterial.diffuseTexture = this.correctTextureTask.texture;
                            this.correctIncorrectMaterial.diffuseTexture.hasAlpha = true;
                        }

                    });
                },1000);
            } else {
                
                BABYLON.Animation.CreateAndStartAnimation('buttonHideAnimation_' + i, button, 'scaling', 60, 15, BABYLON.Vector3.One(), BABYLON.Vector3.Zero(), BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction);
            }
        }


        setTimeout(()=>{
            this.question.isVisible = true;
            this.correctIncorrect.isVisible = false;
    
            this.buttonSelectionLocked = false;

            let easingFunction = new BABYLON.ExponentialEase();
            easingFunction.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);
            for(let i=0; i < this.buttons.length; ++i){
                let button = this.buttons[i];
                BABYLON.Animation.CreateAndStartAnimation('buttonShowAnimation_'+i, button, 'scaling', 60, 15, BABYLON.Vector3.Zero(), BABYLON.Vector3.One(), BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction);
            }

            BABYLON.Animation.CreateAndStartAnimation('questionHShowAnimation', this.question, 'scaling', 60, 15, BABYLON.Vector3.Zero(), BABYLON.Vector3.One(), BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT, easingFunction);


        }, 8000);

    }

    

}

