import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import PageWithScene from './Scene/PageWithScene';
import GalleryModal from './Modal/GalleryModal';
import TitleAndTextModal from './Modal/TitleAndTextModal';
import TitleAndVideoModal from './Modal/TitleAndVideoModal';
import TitleTextAndImageCarouselModal from './Modal/TitleTextAndImageCarouselModal';
import {ImageCarouselAsset} from './Scene/PageWithScene';

class App extends React.Component {

  state = {
    showModal_titleAndText: false,
    showModal_titleTextAndImage: false,
    showModal_titleTextAndVideo: false,
    showModal_titleTextAndImageCarousel: false,
    title: "Title",
    subTitle: "SubTitle",
    text: "Lorum ipsum text",
    image: "",
    video: "",
    images: [],
    imageCarouselAssets : []
      
  }
  
  titleAndTextModalCallback = (title: string, subTitle: string, text: string) => {
    this.setState(
        {
          showModal_titleAndText: true,
          title : title,
          subTitle : subTitle,
          text : text
        }
      );
  }

  titleTextAndImageCallback = (title: string, subTitle: string, text: string, imagePath: string) => {
    //TODO
  }

  titleTextAndVideoCallback = (title: string, subTitle: string, text: string, videoPath: string) => {
    this.setState(
      {
        showModal_titleTextAndVideo: true,
        title : title,
        subTitle : subTitle,
        text : text,
        video : videoPath
      }
    );
  }

  titleTextAndImageCarouselCallback = (title: string, subTitle: string, text: string, imageCarouselAssets: ImageCarouselAsset[]) => {
    this.setState(
      {
        showModal_titleTextAndImageCarousel: true,
        title : title,
        subTitle : subTitle,
        text : text,
        imageCarouselAssets : imageCarouselAssets
      }
    );
  }


  onHideModal = () => {
    this.setState(
      {
        showModal_titleAndText: false,
        showModal_titleTextAndImage: false,
        showModal_titleTextAndVideo: false,
        showModal_titleTextAndImageCarousel: false
      }
    );
  }



  render (){
    return (
      //<div className="App">
      //      <PageWithScene />
      //</div>
//className="container-fluid h-100 p-0"
      [
        <PageWithScene key="babylon" titleAndTextModalCallback={this.titleAndTextModalCallback} titleTextAndImageCallback={this.titleTextAndImageCallback} titleTextAndVideoCallback={this.titleTextAndVideoCallback} titleTextAndImageCarouselCallback={this.titleTextAndImageCarouselCallback}/>,
        <TitleAndTextModal key="TitleAndTextModal" show={this.state.showModal_titleAndText} onHide={this.onHideModal} title={this.state.title} subTitle={this.state.subTitle} text={this.state.text}></TitleAndTextModal>,
        <TitleTextAndImageCarouselModal key="TitleTextAndImageCarouselModal" show={this.state.showModal_titleTextAndImageCarousel} onHide={this.onHideModal} title={this.state.title} subTitle={this.state.subTitle} text={this.state.text} imageCarouselAssets={this.state.imageCarouselAssets}></TitleTextAndImageCarouselModal>,
        <TitleAndVideoModal key="TitleAndVideoModal" show={this.state.showModal_titleTextAndVideo} onHide={this.onHideModal} title={this.state.title} subTitle={this.state.subTitle} text={this.state.text} video={this.state.video}></TitleAndVideoModal>
      ]
      

    );

  }
}

export default App;
