import {Scene, Mesh, AbstractMesh, Nullable, StandardMaterial, Texture, Color3, ActionManager, ExecuteCodeAction, DynamicTexture, Engine, FreeCamera, AssetsManager, TextureAssetTask, BinaryFileAssetTask, Sound} from 'babylonjs';
import {TextBlock} from 'babylonjs-gui'
import NSMSceneBehavior from './NSMSceneBehavior';
import { SceneEventArgs } from './Scene';
import { ImageCarouselAsset } from './PageWithScene';

export class ThreeDClickThroughArt {

    image_mesh: Mesh;
    title_mesh: Mesh;
    text_mesh: Mesh;
    image_paths: string[];
    titles: string[];
    texts: string[];
    index: number;
    scene: Scene;

    constructor(scene: Scene, image_mesh: Mesh, title_mesh: Mesh, text_mesh: Mesh, image_paths?: string[], titles?: string[], texts?: string[], onStart?: () => void, onEnd?: () => void) {
        this.scene = scene;
        this.index = 0;

        this.image_mesh = image_mesh;
        this.title_mesh = title_mesh;
        this.text_mesh = text_mesh;
        
        if(image_paths){
            this.image_paths = image_paths;
            this.set_image(image_paths[0]);        
        }else{
            this.image_paths = []
        }

        if(titles){
            this.titles = titles;
            this.set_title(titles[0]);
        }else{
            this.titles = []
        }    

        if(texts){
            this.texts = texts;
            this.set_text(texts[0]);  
        }else{
            this.texts = []
        } 

        this.image_mesh.actionManager = new ActionManager(scene);

        this.image_mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, 
            () => {
                this.next_image()
            }
        ));
    }

    set_image(image_path: string){
        let material: StandardMaterial = new StandardMaterial("", this.scene);

        material.diffuseTexture = new Texture(image_path, this.scene);
        material.diffuseTexture.hasAlpha = false;
        material.useAlphaFromDiffuseTexture = true;
        material.emissiveColor = new Color3(1,1,1);
        this.image_mesh.material = material;
    }

    set_title(title: string){
        let title_texture: DynamicTexture = new DynamicTexture(title + 'tex', {}, this.scene, true);
        
        let font: string = "bold 44px monospace";
        title_texture.drawText(title, 75, 135, font, "green", "white", true, true);
               
        let title_mat: StandardMaterial = new StandardMaterial("", this.scene);
        title_mat.diffuseTexture = title_texture;

        this.title_mesh.material = title_mat;
    }

    set_text(text: string){
        let text_texture: DynamicTexture = new DynamicTexture(text + 'tex', {}, this.scene, true);
        
        let font: string = "bold 44px monospace";
        text_texture.drawText(text, 75, 135, font, "green", "white", true, true);
               
        let text_mat: StandardMaterial = new StandardMaterial("", this.scene);
        text_mat.diffuseTexture = text_texture;

        this.title_mesh.material = text_mat;
    }

    add_image(image_path: string, title: string, text: string){
        // Set material if this is the first
        if(this.image_paths.length == 0){
            this.set_image(image_path);
        }

        this.image_paths.push(image_path);
        this.titles.push(title);
        this.texts.push(text);

    }

    next_image(){
    
        let image_path: string = this.image_paths[this.index];
        
        this.index++;
        this.index = this.index % this.image_paths.length;

        this.set_image(image_path);
    }
}


export class HTMLVideoArt implements NSMSceneBehavior {

    //  NSM Scene Behavior
    engine: Engine;
    scene: Scene;
    canvas: HTMLCanvasElement;    
    camera: FreeCamera;

    // Class members
    display_mesh_name: string;
    
    videoPath: string;
    title: string;
    subTitle: string;
    text: string;
    showModalCallback: (title: string, subTitle: string, text: string, videoPath: string) => void;
    isLoaded: boolean;
    onceLoadedImagePath: string;

    // Loaded Assets
    display_mesh: Mesh;

    pingSelectMusicTask : BinaryFileAssetTask;
    pingSelectSound : Sound;

    constructor(display_mesh_name: string, showModalCallback: (title: string, subTitle: string, text: string, imagePath: string) => void, videoPath: string, title: string, subTitle: string, text: string) {
        this.display_mesh_name= display_mesh_name;
        this.showModalCallback = showModalCallback;
        this.isLoaded = false;
        this.title = title;
        this.subTitle = subTitle;
        this.text = text;
        this.videoPath = videoPath;

    }

    Start(sceneEventArgs: SceneEventArgs, camera: FreeCamera){
        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
    }

    LoadAssets(assetsManager: AssetsManager){
        this.pingSelectMusicTask = assetsManager.addBinaryFileTask("Pinger Sound task", "./assets/sounds/Chirp UI 1.mp3");
     }

    OnAssetLoadComplete(){ 

        //Sound
        this.pingSelectSound = new Sound("triviaCorrectSound", this.pingSelectMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });

        let artStop2Mesh: Nullable<AbstractMesh> = this.scene.getMeshByName(this.display_mesh_name);
        if(artStop2Mesh != null){
            let amesh: AbstractMesh = artStop2Mesh;
            if(amesh instanceof Mesh){
                this.display_mesh = amesh;
            }
        }

        this.display_mesh.actionManager = new ActionManager(this.scene);

        this.display_mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, 
            () => {
                this.pingSelectSound.play();
                // On button pushed
                this.showModalCallback(this.title, this.subTitle, this.text, this.videoPath);
                console.log("Show modal");
            }
        ));

        this.isLoaded = true;
        if(this.onceLoadedImagePath != null)
            this.SetDisplayImage(this.onceLoadedImagePath);

    }

    Update(){ }    

    SetDisplayImage(image_path: string){
        if(this.isLoaded){
            let material: StandardMaterial = new StandardMaterial("", this.scene);

            let image_texture: Texture = new Texture(image_path, this.scene);
            image_texture.vScale = -1.0;
            material.diffuseTexture = image_texture;

            material.diffuseTexture.hasAlpha = false;
            material.useAlphaFromDiffuseTexture = true;
            material.emissiveColor = new Color3(1,1,1);
            this.display_mesh.material = material;
        }else{
            this.onceLoadedImagePath = image_path;
        }
    }
}


export class HTMLArt implements NSMSceneBehavior {

    //  NSM Scene Behavior
    engine: Engine;
    scene: Scene;
    canvas: HTMLCanvasElement;    
    camera: FreeCamera;

    // Class members
    display_mesh_name: string;
    
    image_path: string;
    title: string;
    subTitle: string;
    text: string;
    showModalCallback: (title: string, subTitle: string, text: string) => void;
    isLoaded: boolean;
    onceLoadedImagePath: string;

    // Loaded Assets
    display_mesh: Mesh;

    pingSelectMusicTask : BinaryFileAssetTask;
    pingSelectSound : Sound;

    constructor(display_mesh_name: string, showModalCallback: (title: string, subTitle: string, text: string) => void, image_path: string, title: string, subTitle: string, text: string) {
        this.display_mesh_name= display_mesh_name;
        this.showModalCallback = showModalCallback;
        this.isLoaded = false;
        this.title = title;
        this.subTitle = subTitle;
        this.text = text;
    }

    Start(sceneEventArgs: SceneEventArgs, camera: FreeCamera){
        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;
    }

    LoadAssets(assetsManager: AssetsManager){ 
        this.pingSelectMusicTask = assetsManager.addBinaryFileTask("Pinger Sound task", "./assets/sounds/Chirp UI 1.mp3");
    }

    OnAssetLoadComplete(){ 

         //Sound
         this.pingSelectSound = new Sound("triviaCorrectSound", this.pingSelectMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });

        let artStop2Mesh: Nullable<AbstractMesh> = this.scene.getMeshByName(this.display_mesh_name);
        if(artStop2Mesh != null){
            let amesh: AbstractMesh = artStop2Mesh;
            if(amesh instanceof Mesh){
                this.display_mesh = amesh;
            }
        }

        this.display_mesh.actionManager = new ActionManager(this.scene);

        this.display_mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, 
            () => {
                this.pingSelectSound.play();
                // On button pushed
                this.showModalCallback(this.title, this.subTitle, this.text);
                console.log("Show modal");
            }
        ));

        this.isLoaded = true;
        if(this.onceLoadedImagePath != null)
            this.SetDisplayImage(this.onceLoadedImagePath);

    }

    Update(){ }    

    SetDisplayImage(image_path: string){
        if(this.isLoaded){
            let material: StandardMaterial = new StandardMaterial("", this.scene);

            let image_texture: Texture = new Texture(image_path, this.scene);
            image_texture.vScale = -1.0;
            material.diffuseTexture = image_texture;
            material.useAlphaFromDiffuseTexture = true;

            material.diffuseTexture.hasAlpha = false;
            material.emissiveColor = new Color3(1,1,1);
            this.display_mesh.material = material;
        }else{
            this.onceLoadedImagePath = image_path;
        }
    }
}

export class HTMLCarouselArt implements NSMSceneBehavior {

    //  NSM Scene Behavior
    engine: Engine;
    scene: Scene;
    canvas: HTMLCanvasElement;    
    camera: FreeCamera;

    // Class members
    display_mesh_name: string;
    title: string;
    subTitle: string;
    text: string;    
    imageCarouselData: ImageCarouselAsset[];
    showModalCallback: (title: string, subTitle: string, text: string, imageCarouselAssets: ImageCarouselAsset[]) => void | undefined; 
    isLoaded: boolean;
    onceLoadedImagePath: string;

    // Loaded Asset 
    display_mesh: Mesh;    

    pingSelectMusicTask : BinaryFileAssetTask;
    pingSelectSound : Sound;

    constructor(display_mesh_name: string, title: string, subTitle: string, text: string, showModalCallback: (title: string, subTitle: string, text: string, imageCarouselAssets: ImageCarouselAsset[]) => void | undefined, imageCarouselData: ImageCarouselAsset[]) {
        this.display_mesh_name= display_mesh_name;
        this.showModalCallback = showModalCallback;
        this.isLoaded = false;
        this.title = title; 
        this.subTitle = this.subTitle;
        this.text = text;
        this.imageCarouselData = imageCarouselData;
    }

    Start(sceneEventArgs: SceneEventArgs, camera: FreeCamera){
        this.canvas = sceneEventArgs.canvas;
        this.scene = sceneEventArgs.scene;
        this.engine = sceneEventArgs.engine;
        this.camera = camera;   
    }

    LoadAssets(assetsManager: AssetsManager){ 
        this.pingSelectMusicTask = assetsManager.addBinaryFileTask("Pinger Sound task", "./assets/sounds/Chirp UI 1.mp3");
    }

    OnAssetLoadComplete(){ 

         //Sound
         this.pingSelectSound = new Sound("triviaCorrectSound", this.pingSelectMusicTask.data, this.scene, undefined, { volume: 1.0, autoplay: false, loop: false });

        // Load the nullable mesh
        let artStop2Mesh: Nullable<AbstractMesh> = this.scene.getMeshByName(this.display_mesh_name);
        if(artStop2Mesh != null){
            let amesh: AbstractMesh = artStop2Mesh;
            if(amesh instanceof Mesh){
                this.display_mesh = amesh;
            }
        }

        this.display_mesh.actionManager = new ActionManager(this.scene);

        this.display_mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickTrigger, 
            () => {

                this.pingSelectSound.play();

                // On button pushed
                this.showModalCallback(this.title, this.subTitle, this.text, this.imageCarouselData);
            }
        ));
    
        this.isLoaded = true;
        if(this.onceLoadedImagePath != null)
            this.SetDisplayImage(this.onceLoadedImagePath);

    }

    Update(){ }    

    SetDisplayImage(image_path: string){
        if(this.isLoaded){
            let material: StandardMaterial = new StandardMaterial("", this.scene);

            let image_texture: Texture = new Texture(image_path, this.scene);
            image_texture.vScale = -1.0;
            material.diffuseTexture = image_texture;

            material.diffuseTexture.hasAlpha = false;
            material.useAlphaFromDiffuseTexture = true;
            material.emissiveColor = new Color3(1,1,1);
            this.display_mesh.material = material;
        }else{
            this.onceLoadedImagePath = image_path;
        }
    }
}